/* For the background gradient in the testimonials card */
.testimonial-background {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 15% 43%,
    rgba(255, 255, 236, 1) 0%,
    rgba(255, 238, 255, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 15% 43%,
    rgba(255, 255, 236, 1) 0%,
    rgba(255, 238, 255, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 15% 43%,
    rgba(255, 255, 236, 1) 0%,
    rgba(255, 238, 255, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 15% 43%,
    rgba(255, 255, 236, 1) 0%,
    rgba(255, 238, 255, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 15% 43%,
    rgba(255, 255, 236, 1) 0%,
    rgba(255, 238, 255, 1) 100%
  );
}

.slider {
  height: 300px;
  margin: auto;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track {
  display: flex;
  width: calc(
    400px * 18
  ); /* Required width multiplied by the length of the testimonial array */
  animation: scroll 40s linear infinite;
}

.slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-400px * 9)
    ); /* Negative width multiplied by half of the length of the testimonial array */
  }
}

.slide {
  display: flex;
  align-items: center;
  padding: 10px;
  perspective: 100px;
}

/* Gradient shadows */

.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.slider::before {
  left: 0;
  top: 0;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
